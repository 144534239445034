import Link from "next/link";
import styles from "./search-items.module.css";
import Image from "next/image";
import { useRouter } from "next/router";
import * as fbq from "../../lib/fbpixel";
import * as ga from "../../lib/ga";

const SearchItems = ({
  searchInput,
  product,
  popUpSearch,
  sideBarSearch,
  dark,
}) => {
  const router = useRouter();
  const searchTitle = product.product_title;
  const productImages = product.product_images[0];
  const productUrl = `/product/${product.product_url}`;

  let salePercentage = product.product_discount_display;
  let regularPrice = product.product_reg_price_display;
  let salePrice = product.product_sale_price_display;

  const latestPromotion = product.promotions.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  if (latestPromotion.length > 0) {
    if (latestPromotion[0].type === "discount") {
      salePrice =
        regularPrice - regularPrice * (latestPromotion[0].amount / 100);
      salePercentage = latestPromotion[0].amount;
    }
    if (latestPromotion[0].type === "fixed") {
      if (product.product_items.length > 0) {
        const maxRegularPriceItem = product.product_items.reduce(
          (max, reg) => (reg.reg_price > max ? reg.reg_price : max),
          0
        );
        regularPrice = maxRegularPriceItem;
      }
      salePrice = latestPromotion[0].amount;
      salePercentage = countPercentagePriceSale(regularPrice, salePrice);
    }
  }

  const goToProduct = () => {
    pushCountSearchProduct();
    facebookPixelAndCAPI();
    eventGASearch();
    // hide search bar on desktop
    if (popUpSearch) {
      popUpSearch.setHeightPopup("0");
    }
    // hide search bar on mobile
    if (sideBarSearch) {
      sideBarSearch.setShowSideBar(false);
    }
    // router.push(productUrl);
    window.location.href = productUrl;
  };

  const eventGASearch = () => {
    ga.event({
      action: "search",
      params: {
        search_term: searchInput,
        product: {
          product_sku: product.product_sku,
          product_name: product.product_title,
          value: product.product_price,
          currency: "IDR",
        },
      },
    });
  };

  const facebookPixelAndCAPI = () => {
    const userData = JSON.parse(localStorage.getItem("_fbu")) ?? null;
    const contentFB = [
      { id: product.product_sku, quantity: product.product_qty },
    ];

    setTimeout(() => {
      const eventName = "Search";
      const payload = {
        event_name: eventName,
        event_id: fbq.eventId(),
        custom_data: {
          content_name: product.product_title,
          content_ids: [product.product_sku],
          content_type: "product_group",
          contents: contentFB,
          value: product.product_price,
          currency: "IDR",
          search_string: searchInput,
        },
        user_data: userData,
      };
      // meta pixel
      fbq.event(eventName, payload.custom_data);
      // CAPI
      // const api_url = process.env.API_URL;
      fetch(`/api/facebook-conversion-api`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        // credentials: "include", // Include this option
      })
        .then((response) => {
          // console.log(`Server Side Event: ${eventName} (${response.status})`);
          // console.log(payload);
        })
        .catch((error) => {
          // console.log(`Server Side Event: ${eventName} (${error.status})`);
        });
    }, 250);
  };

  const pushCountSearchProduct = async () => {
    const api_url = process.env.API_URL;
    const response = await fetch(
      `${api_url}api/products/count/search/${product._id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      console.error("cannot add search product count");
    }
  };

  const isDark = dark ?? false;
  return (
    <>
      <div
        className={`${styles.search_item} ${isDark ? styles.dark : ""}`}
        onClick={goToProduct}
      >
        <Image src={productImages} width={50} height={50} alt={searchTitle} />
        <div className={styles.detail_search_item}>
          <div>{searchTitle}</div>
          <div className={styles.price_item}>
            {salePrice === 0 && (
              <div className={styles.reg_price}>
                Rp {regularPrice.toLocaleString("id-ID")}
              </div>
            )}
            {salePrice > 0 && (
              <>
                <div
                  className={styles.reg_price}
                  style={{
                    textDecoration: "line-through",
                    textDecorationThickness: "from-font",
                  }}
                >
                  Rp {regularPrice.toLocaleString("id-ID")}
                </div>
                <div className={styles.sale_price}>
                  Rp {salePrice.toLocaleString("id-ID")}
                </div>
              </>
            )}
            {salePrice > 0 && (
              <div
                className={`${styles.discount_percentage} ${
                  isDark && styles.dark
                }`}
              >
                {salePercentage}%{" "}
              </div>
            )}
          </div>
        </div>
      </div>
      <hr
        style={{
          margin: "0px",
        }}
      />
    </>
  );
};
export default SearchItems;

const countPercentagePriceSale = (reg_price, sale_price) => {
  if (sale_price === 0) {
    return 0;
  }

  const sale = ((reg_price - sale_price) / reg_price) * 100;
  return Math.round(sale);
};

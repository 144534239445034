import React, { useState, useEffect } from "react";
import styles from "./notice-countdown.module.css"; // Sesuaikan nama file CSS module Anda

const NoticeCountdown = ({
  startDate,
  endDate,
  discountNotice,
  voucherName,
}) => {
  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    const difference = end - now;

    if (now >= start && difference > 0) {
      return {
        Days: Math.floor(difference / (1000 * 60 * 60 * 24))
          .toString()
          .padStart(2, "0"),
        Hours: Math.floor((difference / (1000 * 60 * 60)) % 24)
          .toString()
          .padStart(2, "0"),
        Min: Math.floor((difference / 1000 / 60) % 60)
          .toString()
          .padStart(2, "0"),
        Sec: Math.floor((difference / 1000) % 60)
          .toString()
          .padStart(2, "0"),
      };
    }
    return {};
  };

  const [timeLeft, setTimeLeft] = useState({});
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
    setTimeLeft(calculateTimeLeft());

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [startDate, endDate]);

  if (!isClient) {
    return null;
  }

  const timerComponents = Object.keys(timeLeft).map((interval, index) => (
    <React.Fragment key={interval}>
      <div key={`${interval}-${index}`} className={styles.timer}>
        <div className={styles.clock}>{timeLeft[interval]}</div>
        <div className={styles.description}>{interval}</div>
      </div>
      {index < Object.keys(timeLeft).length - 1 && (
        <div
          key={`${interval}-separator-${index}`}
          className={styles.separator}
        >
          :
        </div>
      )}
    </React.Fragment>
  ));

  return (
    <div className={styles.countdown_notice}>
      <div className={styles.right}>
        <div className={styles.discount}>
          <strong>{discountNotice}</strong>
        </div>
        <div className={styles.discount}>{voucherName}</div>
      </div>
      <div className={styles.left}>
        <div className={styles.count_time}>
          <div className={styles.ends}>ENDS IN</div>
          <div className={styles.timer_comp}>{timerComponents}</div>
        </div>
      </div>
    </div>
  );
};

export default NoticeCountdown;

import MainNavigation from "../layout/navigation/main-navigation";
import MobileNavigation from "../layout/navigation/mobile-navigation";
import UserContext from "../../stores/user-context";
import jwt from "jsonwebtoken";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { useRouter } from "next/router";
import { FaRegCheckCircle, FaRegTimesCircle } from "react-icons/fa";
import { IoWarningOutline } from "react-icons/io5";
import { FiInfo } from "react-icons/fi";
import { SnackbarProvider, MaterialDesignContent } from "notistack";
import { styled } from "@mui/material/styles";
const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: "#DFC8E7",
    color: "#101820",
    // fontWeight: "bold",
    boxShadow: "0px 2px 3px #00000052",
  },
  "&.notistack-MuiContent-warning": {
    backgroundColor: "#F0EC74",
    color: "#101820",
    // fontWeight: "bold",
    boxShadow: "0px 2px 3px #00000052",
  },
  "&.notistack-MuiContent-info": {
    backgroundColor: "#7AA1D5",
    color: "#101820",
    // fontWeight: "bold",
    boxShadow: "0px 2px 3px #00000052",
  },
}));
const Layout = ({ isDesktop, children, dark }) => {
  const router = useRouter();

  const userCtx = useContext(UserContext);
  const loginStatus = userCtx.user ? true : false;
  const [isLogin, setIsLogin] = useState(loginStatus);
  const isLoggingOutRef = useRef(false);
  const { enqueueSnackbar } = useSnackbar();
  const isDark = dark ?? false;

  if (isLogin) {
    const user = localStorage.getItem("user");

    if (user) {
      const token = JSON.parse(user)?.token;

      if (token) {
        try {
          // Decode the JWT token
          const decodedToken = jwt.decode(token);

          // Get the expiration timestamp
          const expirationTimestamp = decodedToken.exp;

          // Compare with the current timestamp
          const currentTimestamp = Math.floor(Date.now() / 1000);

          if (expirationTimestamp < currentTimestamp) {
            // Token has expired
            // Perform auto logout
            autoLogout();
          }
        } catch (error) {
          // Error decoding the token
          console.error("Error decoding JWT token", error);
        }
      } else {
        // No token
        // Perform auto logout
        autoLogout();
      }
    } else {
      // No user
      autoLogout();
    }
  }

  async function autoLogout() {
    try {
      // Check if logout process is already in progress
      if (isLoggingOutRef.current) {
        return;
      }
      // Set the flag to indicate logout process is in progress
      isLoggingOutRef.current = true;

      const api_url = process.env.API_URL;

      const response = await fetch(`${api_url}api/users/logout`, {
        method: "POST",
        credentials: "include",
      });
      if (response) {
        userCtx.setUserContext(null);
        enqueueSnackbar("Session expired, please log in again", {
          variant: "warning",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      // Reset the flag after the logout process is complete
      isLoggingOutRef.current = false;
    }
  }

  useEffect(() => {
    setIsLogin(!!userCtx.user);
  }, [userCtx.user]);

  // affiliate code param

  useEffect(() => {
    // Get the value of the 'affiliateCode' query parameter from the URL
    const affiliateCode = router.query.aff;

    // Check if 'affiliateCode' exists in the URL
    if (affiliateCode) {
      // 'affiliateCode' exists, set its value in sessionStorage
      sessionStorage.setItem("aff", affiliateCode.toString());
    }

    // Now you can retrieve the value from sessionStorage whenever you need it
    sessionStorage.getItem("aff");
  }, [router.query.aff]);

  // partner code param

  useEffect(() => {
    // Get the value of the 'partnerCode' query parameter from the URL
    const partnerCode = router.query.partner_code;

    // Check if 'partnerCode' exists in the URL
    if (partnerCode) {
      // 'partnerCode' exists, set its value in sessionStorage
      sessionStorage.setItem("partner_code", partnerCode.toString());
    }

    // Now you can retrieve the value from sessionStorage whenever you need it
    sessionStorage.getItem("partner_code");
  }, [router.query.partner_code]);

  return (
    <SnackbarProvider
      Components={{
        success: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
      }}
      maxSnack={3}
      iconVariant={{
        success: (
          <div className="icon-snackbar">
            <FaRegCheckCircle />
          </div>
        ),
        error: (
          <div className="icon-snackbar">
            <FaRegTimesCircle />
          </div>
        ),
        warning: (
          <div className="icon-snackbar">
            <IoWarningOutline />
          </div>
        ),
        info: (
          <div className="icon-snackbar">
            <FiInfo />
          </div>
        ),
      }}
    >
      <div className="relative">
        {isDesktop ? (
          <MainNavigation dark={isDark} />
        ) : (
          <MobileNavigation dark={isDark} />
        )}
        <main>{children}</main>
      </div>
    </SnackbarProvider>
  );
};

export default Layout;

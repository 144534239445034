export const menuList = [
  {
    title_menu: "What's New",
    url_path: "/whats-new",
    class: "whats_new_menu",
    menu: [],
  },
  {
    title_menu: "Shop",
    url_path: "#",
    menu: [
      {
        name: "Categories",
        url_path: "#",
        is_sub_title: true,
        sub_menu: [
          {
            name: "Jewelry Set",
            url_path: "/bundle/jewelry-set",
            is_sub_title: false,
            sub_menu: [],
            image:
              "https://res.cloudinary.com/aurumlab/image/upload/v1729482664/aurumlab/menu%20image/banner-katego-ri-2i_pb3esz.png",
          },
          {
            name: "Fragrance",
            url_path: "/product-category/fragrance",
            is_sub_title: false,
            sub_menu: [],
            image:
              "https://res.cloudinary.com/aurumlab/image/upload/v1723100969/aurumlab/banner/collection/fragrance/banner_kategori_parfumeArtboard_26_u7pkrt.webp",
          },
          {
            name: "Ring",
            url_path: "/product-category/ring",
            is_sub_title: false,
            sub_menu: [],
            image:
              "https://res.cloudinary.com/aurumlab/image/upload/v1718151417/aurumlab/menu%20image/cincinn_dc8cui.webp",
          },
          {
            name: "Earrings",
            url_path: "/product-category/earrings",
            is_sub_title: false,
            sub_menu: [],
            image:
              "https://res.cloudinary.com/aurumlab/image/upload/v1718151415/aurumlab/menu%20image/anting_geqbtj.webp",
          },
          {
            name: "Bracelet",
            url_path: "/product-category/bracelet",
            is_sub_title: false,
            sub_menu: [],
            image:
              "https://res.cloudinary.com/aurumlab/image/upload/v1718151409/aurumlab/menu%20image/gelang_eiidsx.webp",
          },
          {
            name: "Necklace",
            url_path: "/product-category/necklace",
            is_sub_title: false,
            sub_menu: [],
            image:
              "https://res.cloudinary.com/aurumlab/image/upload/v1718151409/aurumlab/menu%20image/kalung_irm74k.webp",
          },
          {
            name: "Pendant",
            url_path: "/product-category/pendant",
            is_sub_title: false,
            sub_menu: [],
            image:
              "https://res.cloudinary.com/aurumlab/image/upload/v1718151419/aurumlab/menu%20image/peendant_i229ef.webp",
          },
          {
            name: "Charms",
            url_path: "/product-category/charms",
            is_sub_title: false,
            sub_menu: [],
            image:
              "https://res.cloudinary.com/aurumlab/image/upload/v1718151416/aurumlab/menu%20image/charms_p2zaoz.webp",
          },
          {
            name: "Anklet",
            url_path: "/product-category/anklet",
            is_sub_title: false,
            sub_menu: [],
            image:
              "https://res.cloudinary.com/aurumlab/image/upload/v1718151413/aurumlab/menu%20image/anklet_dg2rdk.webp",
          },
          {
            name: "Wedding",
            url_path: "/product-category/wedding",
            is_sub_title: false,
            sub_menu: [],
            image:
              "https://res.cloudinary.com/aurumlab/image/upload/v1718078391/aurumlab/menu%20image/wedding_ring_new_nleoa4.webp",
          },
          {
            name: "Engagement",
            url_path: "/product-category/engagement",
            is_sub_title: false,
            sub_menu: [],
            image:
              "https://res.cloudinary.com/aurumlab/image/upload/v1718151409/aurumlab/menu%20image/engangement_mblagi.webp",
          },
          {
            name: "All Jewelries",
            url_path: "/all-jewelries",
            is_sub_title: false,
            sub_menu: [],
            image: "",
          },
        ],
      },
      {
        name: "Collection",
        url_path: "#",
        is_sub_title: true,
        sub_menu: [
          {
            name: "Wedding & Diamond",
            url_path: "/collection/wedding",
            is_sub_title: false,
            sub_menu: [],
            image:
              "https://res.cloudinary.com/aurumlab/image/upload/v1727749838/aurumlab/menu%20image/banner-kategori--wedding-ring-new_cqkrwa.webp",
          },
          {
            name: "Aurum Lab Fragrance",
            url_path: "/fragrance",
            is_sub_title: false,
            sub_menu: [],
            image:
              "https://res.cloudinary.com/aurumlab/image/upload/v1723100969/aurumlab/banner/collection/fragrance/banner_kategori_parfumeArtboard_26_u7pkrt.webp",
          },
          {
            name: "Birthstone",
            url_path: "/collection/birthstone",
            is_sub_title: false,
            sub_menu: [],
            image:
              "https://res.cloudinary.com/aurumlab/image/upload/v1720054151/aurumlab/menu%20image/birthstone_b91ywp.webp",
          },
          {
            name: "Charms",
            url_path: "/collection/charms",
            is_sub_title: false,
            sub_menu: [],
            image:
              "https://res.cloudinary.com/aurumlab/image/upload/v1718151415/aurumlab/menu%20image/charms_koleksi_pbcfqh.webp",
          },
          {
            name: "Personal",
            url_path: "/collection/personal",
            is_sub_title: false,
            sub_menu: [],
            image:
              "https://res.cloudinary.com/aurumlab/image/upload/v1718151418/aurumlab/menu%20image/personal_pfkmyo.webp",
          },
          {
            name: "Mother of Pearl",
            url_path: "/collection/mother-of-pearl",
            is_sub_title: false,
            sub_menu: [],
            image:
              "https://res.cloudinary.com/aurumlab/image/upload/v1718151420/aurumlab/menu%20image/mother_of_pearl_wblihb.webp",
          },
          {
            name: "Disney Collections",
            url_path: "/disney",
            is_sub_title: false,
            sub_menu: [],
            image:
              "https://res.cloudinary.com/aurumlab/image/upload/v1718151410/aurumlab/menu%20image/disney_huuvbw.webp",
          },
          {
            name: "Voyage (Aurum Lab x Andrea Dian)",
            url_path: "/collection/voyage",
            is_sub_title: false,
            sub_menu: [],
            image:
              "https://res.cloudinary.com/aurumlab/image/upload/v1718151413/aurumlab/menu%20image/andrea_htob6r.webp",
          },
          {
            name: "Aurum SIX",
            url_path: "/collection/six",
            is_sub_title: false,
            sub_menu: [],
            image:
              "https://res.cloudinary.com/aurumlab/image/upload/v1718151412/aurumlab/menu%20image/6k_neiaxs.webp",
          },

          {
            name: "Emily in Paris",
            url_path: "/collection/emily-in-paris",
            is_sub_title: false,
            sub_menu: [],
            image:
              "https://res.cloudinary.com/aurumlab/image/upload/v1718151410/aurumlab/menu%20image/emily_in_paris_x28fsi.webp",
          },

          {
            name: "All Collection",
            url_path: "/collection",
            is_sub_title: false,
            sub_menu: [],
            image: "",
          },
        ],
      },

      {
        name: "Materials",
        url_path: "#",
        is_sub_title: true,
        sub_menu: [
          {
            name: "6K Rose Gold",
            url_path: "/product-tag/6k rose gold",
            is_sub_title: false,
            sub_menu: [],
            image: "",
          },
          {
            name: "9K Yellow Gold",
            url_path: "/product-tag/9k yellow gold",
            is_sub_title: false,
            sub_menu: [],
            image: "",
          },
          {
            name: "9K White Gold",
            url_path: "/product-tag/9k white gold",
            is_sub_title: false,
            sub_menu: [],
            image: "",
          },
          {
            name: "9K Rose Gold",
            url_path: "/product-tag/9k rose gold",
            is_sub_title: false,
            sub_menu: [],
            image: "",
          },
          {
            name: "Diamond Stone",
            url_path: "/product-tag/diamond stone",
            is_sub_title: false,
            sub_menu: [],
            image: "",
          },
          {
            name: "Zirconia Stone",
            url_path: "/product-tag/zirconia stone",
            is_sub_title: false,
            sub_menu: [],
            image: "",
          },
          {
            name: "Pearls",
            url_path: "/product-tag/pearls",
            is_sub_title: false,
            sub_menu: [],
            image: "",
          },
          {
            name: "Mother of Pearl",
            url_path: "/product-tag/mother of pearl",
            is_sub_title: false,
            sub_menu: [],
            image: "",
          },
        ],
      },
    ],
  },
  {
    title_menu: "Gift",
    url_path: "#",
    menu: [
      {
        name: "Gifts",
        url_path: "#",
        is_sub_title: true,
        sub_menu: [
          // {
          //   name: "Gift Guide",
          //   url_path: "/gift-guide",
          //   is_sub_title: false,
          //   is_blank: true,
          //   sub_menu: [],
          //  image: "",
          // },
          {
            name: "Gift Card",
            url_path: "/gift-card",
            is_sub_title: false,
            sub_menu: [],
            image: "",
          },
          {
            name: "Animated Gift Card",
            url_path: "/animated-gift-card",
            is_sub_title: false,
            sub_menu: [],
            image: "",
          },
          {
            name: "Gift Box",
            url_path: "/gift-box",
            is_sub_title: false,
            sub_menu: [],
            image: "",
          },
        ],
      },
      {
        name: "Gifts by Price/Budget?",
        url_path: "#",
        is_sub_title: true,
        sub_menu: [
          {
            name: "Gifts under 1 mio",
            url_path: "/product-category/gift-by-price/gift-under-1-mio/",
            is_sub_title: false,
            sub_menu: [],
            image: "",
          },
          {
            name: "Gifts 1 - 2 mio",
            url_path: "/product-category/gift-by-price/gift-1-2-mio/",
            is_sub_title: false,
            sub_menu: [],
            image: "",
          },
          {
            name: "Gifts 2 - 3 mio",
            url_path: "/product-category/gift-by-price/gift-2-3-mio/",
            is_sub_title: false,
            sub_menu: [],
            image: "",
          },
          {
            name: "Gifts over 3 mio",
            url_path: "/product-category/gift-by-price/gift-over-3-mio/",
            is_sub_title: false,
            sub_menu: [],
            image: "",
          },
        ],
      },
    ],
  },
  {
    title_menu: "Who We Are",
    url_path: "#",
    menu: [
      {
        name: "",
        url_path: "#",
        is_sub_title: true,
        sub_menu: [
          {
            name: "The Brand",
            url_path: "/the-brand",
            is_sub_title: false,
            sub_menu: [],
            image: "",
          },
          {
            name: "Magazine",
            url_path: "/magazine",
            is_sub_title: false,
            sub_menu: [],
            image: "",
          },
          {
            name: "Catalog",
            url_path: "/magazine-catalog",
            is_sub_title: false,
            sub_menu: [],
            image: "",
          },
          {
            name: "Monthly Issue",
            url_path: "/monthly-issue",
            is_sub_title: false,
            sub_menu: [],
            image: "",
          },
        ],
      },
    ],
  },
  {
    title_menu: "Our Store",
    url_path: "#",
    menu: [
      {
        name: "Offline Store",
        url_path: "#",
        is_sub_title: true,
        sub_menu: [
          {
            name: "Location",
            url_path: "/our-store",
            is_sub_title: false,
            sub_menu: [],
            image: "",
          },
          {
            name: "Catalog",
            url_path: "/store-catalog",
            is_sub_title: false,
            sub_menu: [],
            image: "",
          },
        ],
      },
    ],
  },
  {
    title_menu: "DISC 47%",
    url_path: "/sale-up-to-47",
    class: "last_change",
    menu: [],
  },
];

import { useRef, useState, useEffect } from "react";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import Logo from "../logo";
import ChartHeader from "../chart-header";
import styles from "./mobile-navigation.module.css";
import SideBarNavigation from "./side-bar-navigation";
import StoreNotice from "../store-notice";

const MobileNavigation = ({ dark }) => {
  const [showSideBar, setShowSideBar] = useState(false);
  const isDark = dark ?? false;
  const storeNoticeRef = useRef();
  const [headerTop, setHeaderTop] = useState();

  useEffect(() => {
    // Update headerTop with initial clientHeight when the component mounts
    if (storeNoticeRef.current) {
      setHeaderTop(storeNoticeRef.current.clientHeight + 15);
    }

    // Event listener for scroll and resize
    const handleScrollAndResize = () => {
      if (storeNoticeRef.current) {
        setHeaderTop(
          Math.max(0, storeNoticeRef.current.clientHeight - window.scrollY)
        );
      }
    };

    // Attach the event listener to scroll and resize events
    window.addEventListener("scroll", handleScrollAndResize);
    window.addEventListener("resize", handleScrollAndResize);

    // Cleanup the event listeners when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScrollAndResize);
      window.removeEventListener("resize", handleScrollAndResize);
    };
  }, []);

  return (
    <>
      <div ref={storeNoticeRef}>
        <StoreNotice />
      </div>
      <header className={styles.has_sticky}>
        <div
          style={{ top: `${headerTop}px` }}
          className={`${styles.header_section} ${isDark ? styles.dark : ""}`}
        >
          <div
            className={styles.hamburger_menu}
            onClick={() => setShowSideBar(true)}
          >
            <HiOutlineMenuAlt1 />
          </div>
          <Logo dark={isDark} />
          <ChartHeader dark={isDark} />
        </div>
      </header>
      {showSideBar && (
        <SideBarNavigation
          sideBar={{ showSideBar, setShowSideBar }}
          dark={isDark}
        />
      )}
    </>
  );
};
export default MobileNavigation;

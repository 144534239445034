import Image from "next/image";
import Link from "next/link";
import styles from "./logo.module.css";
const Logo = ({ dark }) => {
  const imageSrc = dark ? "/img/AURUM LAB-01.png" : "/img/AURUM LAB-02.png";

  return (
    <>
      <Link href="/" prefetch={false}>
        <Image
          src={imageSrc}
          className={styles.logo}
          width={200}
          height={50}
          priority
          alt="Logo Aurum Lab"
        />
      </Link>
    </>
  );
};
export default Logo;

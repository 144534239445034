import Link from "next/link";
import styles from "./side-bar-navigation.module.css";
import {
  useRef,
  useState,
  useEffect,
  Fragment,
  useCallback,
  useContext,
} from "react";
import { menuListMobile } from "../../../utils/menu-list-mobile";
import { TfiClose } from "react-icons/tfi";
import { BsArrowLeft } from "react-icons/bs";
import { HiChevronDown } from "react-icons/hi2";
import { Input, InputAdornment } from "@mui/material";
import Image from "next/image";
import SearchItems from "../search-items";
import UserContext from "../../../stores/user-context";
import { useRouter } from "next/router";

const NavButton = ({
  onClick,
  name,
  isActive,
  hasSubNav,
  url_path,
  is_blank,
}) => (
  <div onClick={() => onClick(name)} className={isActive ? "active" : ""}>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>
        {url_path !== "#" && (
          <Link href={url_path} prefetch={false} target={is_blank && "_blank"}>
            <div className={styles.menu}>{name}</div>
          </Link>
        )}
        {url_path === "#" && <span className={styles.menu}>{name}</span>}
      </div>
      <div>
        {hasSubNav && hasSubNav.length > 0 ? (
          <HiChevronDown
            style={{
              transform: isActive ? "rotateZ(180deg)" : "rotateZ(0deg)",
              transition: ".2s ease all",
            }}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  </div>
);

const SubMenu = ({ item, activeItem, handleClick }) => {
  const navRef = useRef();
  const isSubNavOpen = (item, items) =>
    items.some((i) => i === activeItem || item === activeItem);

  return (
    <div
      className={`sub-nav ${
        isSubNavOpen(item.title_menu, item.menu) ? "open" : ""
      }`}
      style={{
        height: !isSubNavOpen(item.title_menu, item.menu)
          ? 0
          : navRef.current?.clientHeight + 20,
        overflow: "hidden",
        transition: "0.2s",
        marginLeft: "5px",
      }}
    >
      <ul ref={navRef} className={styles.sub_nav_inner}>
        {item?.menu.map((subItem) => (
          <li key={subItem.name} className={styles.sub_menu}>
            {subItem.name !== "" && (
              <div className={styles.sub_title}>{subItem.name}</div>
            )}
            {subItem.sub_menu.map((listMenu) => (
              <div key={listMenu.name}>
                <NavButton
                  onClick={handleClick}
                  name={listMenu.name}
                  isActive={activeItem === listMenu}
                  url_path={listMenu.url_path}
                  is_blank={listMenu.is_blank}
                />
              </div>
            ))}
          </li>
        ))}
      </ul>
    </div>
  );
};

const SideBarNavigation = (props) => {
  const userCtx = useContext(UserContext);

  const [activeItem, setActiveItem] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [displaySearchResult, setDisplaySearchResult] = useState("none");
  const [searchItems, setSearchItems] = useState([]);
  const loginStatus = userCtx.user ? true : false;
  const [isLogin, setIsLogin] = useState(loginStatus);

  const isDark = props.dark ?? false;
  useEffect(() => {
    setIsLogin(!!userCtx.user);
  }, [userCtx.user]);

  const handleClick = (item) => {
    setActiveItem(item !== activeItem ? item : "");
  };

  //   Searching
  const searchProducts = useCallback(async () => {
    try {
      const api_url = process.env.API_URL;
      const res = await fetch(`${api_url}api/products?q=${searchInput}`);
      const products = await res.json();
      setSearchItems(products);
    } catch (error) {
      setSearchItems([]);
    }
  }, [searchInput]);
  useEffect(() => {
    if (searchInput !== "") {
      setDisplaySearchResult("block");
      if (searchInput.length >= 3) {
        searchProducts();
      } else {
        setSearchItems([]);
      }
    } else {
      setDisplaySearchResult("none");
    }
  }, [searchInput, searchProducts]);

  const [activeSearch, setActiveSearch] = useState(false);
  const showSearchResult = (event) => {
    setSearchInput(event.target.value);
  };

  const closeSideBar = () => {
    props.sideBar.setShowSideBar(false);
  };

  return (
    <>
      <div className={styles.side_bar_overlay} onClick={closeSideBar}></div>
      <div className={`${styles.side_bar_canvas} ${isDark ? styles.dark : ""}`}>
        {/* Header  */}
        {!activeSearch ? (
          <>
            <div className={styles.header_side_bar}>
              <div
                className={`${styles.close_icon} ${isDark ? styles.dark : ""}`}
                onClick={closeSideBar}
              >
                <TfiClose />
              </div>
              <div
                className={styles.search_icon}
                onClick={() => setActiveSearch(true)}
              >
                <Image
                  style={{ filter: isDark && "invert(1)" }}
                  src="/icons/search-icon.png"
                  width={20}
                  height={20}
                  alt="Search Icon"
                />
              </div>
            </div>
            <div className={styles.list_menu}>
              <nav className={styles.nav_menu}>
                <ul>
                  {menuListMobile.map((item, key) => (
                    <li key={key}>
                      {item.menu.length === 0 && (
                        <NavButton
                          onClick={(handleClick, closeSideBar)}
                          name={item.title_menu}
                          isActive={activeItem === item.title_menu}
                          hasSubNav={item.menu}
                          url_path={item.url_path}
                        />
                      )}
                      {item.menu.length > 0 && (
                        <>
                          <NavButton
                            onClick={handleClick}
                            name={item.title_menu}
                            isActive={activeItem === item.title_menu}
                            hasSubNav={item.menu}
                            url_path={item.url_path}
                          />
                          <SubMenu
                            activeItem={activeItem}
                            handleClick={(handleClick, closeSideBar)}
                            item={item}
                          />{" "}
                        </>
                      )}
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </>
        ) : (
          <>
            <div className={styles.header_side_bar}>
              <div className={styles.input_search_section}>
                <Input
                  id="search-input-mobile"
                  className={`${styles.search_input} ${
                    isDark ? styles.dark : ""
                  }`}
                  placeholder="What are you searching for ?"
                  onKeyUp={showSearchResult}
                  startAdornment={
                    <InputAdornment position="start">
                      <BsArrowLeft
                        style={{ filter: isDark && "invert(1)" }}
                        onClick={() => setActiveSearch(false)}
                      />
                    </InputAdornment>
                  }
                />
                <Image
                  className={styles.search_icon}
                  style={{ filter: isDark && "invert(1)" }}
                  src="/icons/search-icon.png"
                  width={20}
                  height={20}
                  alt="Search Icon"
                />
              </div>
            </div>
            {searchInput === "" ? (
              <>
                <div className={styles.search_section}>
                  {/* <TrandingSearchItems dark={isDark} /> */}
                  <TopSearchItems dark={isDark} closeSideBar={closeSideBar} />
                </div>
              </>
            ) : (
              <div
                className={`${styles.search_result} ${
                  isDark ? styles.dark : ""
                }`}
                style={{
                  display: displaySearchResult,
                }}
              >
                <div
                  className={`${styles.search_result_title} ${
                    isDark && styles.dark
                  }`}
                >
                  Search Result
                </div>
                {searchInput.length >= 3 && searchItems.length === 0 && (
                  <div>Search for &quot;{searchInput}&quot; not found</div>
                )}
                {searchInput.length > 0 && searchInput.length < 3 && (
                  <div>
                    Please type &quot;{3 - searchInput.length}&quot; again to
                    continue searching for product.
                  </div>
                )}
                <div
                  className={`${styles.search_result_items} ${
                    isDark && styles.dark
                  }`}
                >
                  {searchItems.length > 0 &&
                    searchItems.map((item, key) => (
                      <div key={key}>
                        <SearchItems
                          product={item}
                          popUpSearch={false}
                          sideBarSearch={props.sideBar}
                          dark={isDark}
                        />
                      </div>
                    ))}
                </div>
              </div>
            )}
          </>
        )}

        {/* Footer  */}
        {!isLogin ? (
          <div className={styles.footer_side_bar}>
            <Link href="/login" prefetch={false}>
              <div className={styles.account} onClick={closeSideBar}>
                Login to account
              </div>
            </Link>
          </div>
        ) : (
          <div className={styles.footer_side_bar}>
            <Link href="/account" prefetch={false}>
              <div className={styles.account} onClick={closeSideBar}>
                My Account
              </div>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};
export default SideBarNavigation;

const TopSearchItems = ({ dark, closeSideBar }) => {
  const [topProducts, setTopProducts] = useState([]);
  const router = useRouter();

  const fetchTopProducts = async () => {
    const api_url = process.env.API_URL;
    const response = await fetch(`${api_url}api/products/count/search/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      console.error("cannot show top search product ");
      return;
    }

    const { topProducts } = await response.json();
    setTopProducts(topProducts);
  };
  useEffect(() => {
    fetchTopProducts();
  }, []);

  const gotoProduct = (productUrl) => {
    closeSideBar();
    // router.push(`/product/${productUrl}`);
    window.location.href = `/product/${productUrl}`;
  };

  const isDark = dark ?? false;
  return (
    <>
      <div className={`${styles.top_search} ${isDark ? styles.dark : ""}`}>
        TOP SEARCH
      </div>
      <div className={styles.top_search_items}>
        {topProducts.map((product, key) => (
          <div key={key} onClick={() => gotoProduct(product.product_url)}>
            <Image
              src={product.product_images[0] ?? "/img/loading_image.png"}
              width={100}
              height={100}
              quality={70}
              alt={product.product_title}
            />
          </div>
        ))}
      </div>
    </>
  );
};

// const TrandingSearchItems = ({ dark }) => {
//   const trandingItems = ["Rings", "Bracelet", "Necklace"];
//   const isDark = dark ?? false;
//   return (
//     <>
//       <div className={`${styles.search_trending} ${isDark ? styles.dark : ""}`}>
//         TRENDING
//       </div>
//       <div className={styles.top_trending_category}>
//         {trandingItems.map((item, key) => (
//           <Fragment key={key}>
//             <div className={styles.trending_category}>{item}</div>
//           </Fragment>
//         ))}
//       </div>
//     </>
//   );
// };

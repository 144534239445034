import { useContext, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import styles from "./chart-header.module.css";
import CartContext from "../../stores/cart-context";

const ChartHeader = ({ dark }) => {
  const cartCtx = useContext(CartContext);
  const isDark = dark ?? false;
  return (
    <>
      <Link href="/cart" prefetch={false}>
        <div className="relative">
          <Image
            src="/icons/chart-icon.png"
            className={`${styles.cart_image} ${isDark ? styles.dark : ""}`}
            width={22}
            height={22}
            alt="Chart Icon"
          />
          <span className={styles.cart_length}>
            {cartCtx.totalCart > 0 ? cartCtx.totalCart : ""}
          </span>
        </div>
      </Link>
    </>
  );
};
export default ChartHeader;

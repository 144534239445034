import Image from "next/image";
import { useState, useRef, useEffect, useCallback } from "react";
import styles from "./search-header.module.css";
import { TfiClose } from "react-icons/tfi";
import Logo from "./logo";
import Link from "next/link";
import { TextField } from "@mui/material";
import SearchItems from "./search-items";
import { useRouter } from "next/router";

const SearchHeader = ({ dark }) => {
  const [heightPopup, setHeightPopup] = useState("0");
  const [displaySearchResult, setDisplaySearchResult] = useState("none");
  const [searchInput, setSearchInput] = useState("");
  const [searchItems, setSearchItems] = useState([]);
  const isDark = dark ?? false;
  const router = useRouter();

  const searchProducts = useCallback(async () => {
    try {
      const api_url = process.env.API_URL;
      const res = await fetch(`${api_url}api/products?q=${searchInput}`);
      const products = await res.json();
      setSearchItems(products);
    } catch (error) {
      setSearchItems([]);
    }
  }, [searchInput]);

  const showSearchPopup = () => {
    setHeightPopup("600px");
  };
  const hideSearchPopup = () => {
    setHeightPopup("0");
  };
  const showSearchResult = (event) => {
    setSearchInput(event.target.value);
  };
  useEffect(() => {
    if (searchInput !== "") {
      setDisplaySearchResult("block");
      if (searchInput.length >= 3) {
        searchProducts();
      } else {
        setSearchItems([]);
      }
    } else {
      setDisplaySearchResult("none");
    }
  }, [searchInput, searchProducts]);

  const [topProducts, setTopProducts] = useState([]);

  const fetchTopProducts = async () => {
    const api_url = process.env.API_URL;
    const response = await fetch(`${api_url}api/products/count/search/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      console.error("cannot show top search product ");
      return;
    }

    const { topProducts } = await response.json();
    setTopProducts(topProducts);
  };
  useEffect(() => {
    fetchTopProducts();
  }, []);

  const goToProduct = (product_url) => {
    hideSearchPopup();
    // router.push(`/product/${product_url}`);
    window.location.href = `/product/${product_url}`;
  };

  return (
    <>
      <div>
        <div
          className={`${styles.search_icon} ${isDark ? styles.dark : ""}`}
          onClick={showSearchPopup}
        >
          <Image
            src="/icons/search-icon.png"
            width={22}
            height={22}
            alt="Search Icon"
          />
        </div>
        <div
          className={`${styles.canvas_search_position} ${
            isDark && styles.dark
          }`}
          style={{
            maxHeight: heightPopup,
          }}
        >
          <div className={styles.close_icon_popup} onClick={hideSearchPopup}>
            <TfiClose />
          </div>
          <div className={styles.canvas_search}>
            <div className={styles.search_logo}>
              <Logo dark={isDark} />
            </div>
            <div className={styles.search}>
              <div className={styles.input_search_section}>
                <TextField
                  id="search-input"
                  label="What are you searching for ?"
                  variant="standard"
                  className={`${styles.search_input} ${
                    isDark ? styles.dark : ""
                  }`}
                  onKeyUp={showSearchResult}
                />
                <Image
                  style={{ filter: isDark && "invert(1)" }}
                  src="/icons/search-icon.png"
                  width={20}
                  height={20}
                  alt="Search Icon"
                />
              </div>
              <div className={styles.below_search}>
                <div className={styles.trending_section}>
                  {/* <div
                    className={`${styles.trending} ${
                      isDark ? styles.dark : ""
                    }`}
                  >
                    <div
                      className={`${styles.trending_title} ${
                        isDark && styles.dark
                      }`}
                    >
                      Trending
                    </div>
                    <div className={styles.trending_category}>
                      <div>Bracelet</div>
                      <div>Ring</div>
                      <div>Necklace</div>
                      <div>Blooms</div>
                      <div>Braveness</div>
                    </div>
                  </div> */}
                  <div className={styles.top_search_section}>
                    <div
                      className={`${styles.top_search} ${
                        isDark && styles.dark
                      }`}
                    >
                      <div
                        className={`${styles.top_search_title} ${
                          isDark && styles.dark
                        }`}
                      >
                        Top Searches
                      </div>
                      <div className={styles.top_search_products}>
                        {topProducts.map((product, key) => (
                          <div
                            key={key}
                            onClick={() => goToProduct(product.product_url)}
                          >
                            <Image
                              src={
                                product.product_images[0] ??
                                "/img/loading_image.png"
                              }
                              width={100}
                              height={100}
                              quality={70}
                              alt={product.product_title}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`${styles.search_result} ${
                    isDark ? styles.dark : ""
                  }`}
                  style={{
                    display: displaySearchResult,
                  }}
                >
                  <div
                    className={`${styles.search_result_title} ${
                      isDark && styles.dark
                    }`}
                  >
                    Search Result
                  </div>
                  {searchInput.length >= 3 && searchItems.length === 0 && (
                    <div>Search for &quot;{searchInput}&quot; not found</div>
                  )}
                  {searchInput.length > 0 && searchInput.length < 3 && (
                    <div>
                      Please type &quot;{3 - searchInput.length}&quot; again to
                      continue searching for product.
                    </div>
                  )}
                  <div
                    className={`${styles.search_result_items} ${
                      isDark && styles.dark
                    }`}
                  >
                    {searchItems.length > 0 &&
                      searchItems.map((item, key) => (
                        <div key={key}>
                          <SearchItems
                            searchInput={searchInput}
                            product={item}
                            popUpSearch={{ setHeightPopup }}
                            sideBarSearch={false}
                            dark={isDark}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SearchHeader;

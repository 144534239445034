import { useContext } from "react";
import Modal from "@mui/material/Modal";
import styles from "./modal-logout.module.css";
import ButtonUi from "../../ui/button-ui";
import UserContext from "../../stores/user-context";
import { useSnackbar } from "notistack";

const ModalLogout = ({ openModal: { openModal, setOpenModal } }) => {
  const userCtx = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    setOpenModal(false);
    try {
      const api_url = process.env.API_URL;

      const response = await fetch(`${api_url}api/users/logout`, {
        method: "POST",
        credentials: "include",
      });
      const res = await response.json();
      if (!response.ok) {
        enqueueSnackbar(res.message, { variant: "warning" });
        userCtx.setUserContext(null);
        return;
      }
      userCtx.setUserContext(null);
      enqueueSnackbar(res.message, { variant: "success" });

      // reload
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.modal}>
          <h3 style={{ marginBottom: "5px" }}>Are you sure ? </h3>
          <div className={styles.buttons}>
            <div className={styles.confirm_button} onClick={handleLogout}>
              <ButtonUi button_class="button_primary">Logout</ButtonUi>
            </div>
            <div></div>
            <div
              className={styles.cancel_button}
              onClick={() => setOpenModal(false)}
            >
              <ButtonUi button_class="button_no_bg">Cancel</ButtonUi>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default ModalLogout;

import styles from "./block-main-navigation.module.css";
const BlockMainNavigation = () => {
  const themeData = JSON.parse(localStorage.getItem("theme"));
  const isDark = themeData === "dark" ? true : false;

  return (
    <>
      <div
        className={`${styles.block_main_navigation} ${
          isDark ? styles.dark : ""
        }`}
      ></div>
    </>
  );
};
export default BlockMainNavigation;
